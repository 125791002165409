$(function () {
  // Add smooth scrolling to links
  $(".navigation a, a.scroll").on("click", function (event) {
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      var hash = this.hash;
      var header = $("header nav");

      if ($(hash).offset()) {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top - $(header).height(),
          },
          800
        );
      } else {
        document.location.href = "/index.html" + hash;
      }
    }
  });

  $(window).on("scroll", function (event) {
    if ($(this).scrollTop() > 40) {
      $("header").addClass("small");
      $("header .container").removeClass("flex-column");
    } else {
      $("header").removeClass("small");
      $("header .container").addClass("flex-column");
    }
  });
});

// Parallax
// (function ($) {
//   /** change value here to adjust parallax level */
//   var parallax = -0.5;

//   var $bg_images = $(".wp-block-cover-image");
//   var offset_tops = [];
//   $bg_images.each(function (i, el) {
//     offset_tops.push($(el).offset().top);
//   });

//   $(window).scroll(function () {
//     var dy = $(this).scrollTop();
//     $bg_images.each(function (i, el) {
//       var ot = offset_tops[i];
//       $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
//     });
//   });
// })(jQuery);
